import React from "react";

import JuGobLogo from "assets/img/brand/jugob/gobierno-logo-jujuy.webp";
import JuGobLogoLight from "assets/img/brand/jugob/gobierno-logo-jujuy-light.webp";
import JuGobFav from "assets/img/brand/jugob/jugob_iso.webp";
import DefaultJuGob from "assets/img/brand/jugob/defaultJuGob.webp";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'jugob';

export const sources = {
    jugob: {
        url: document.location.origin,
        logo: JuGobLogo,
        logolight: JuGobLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Gob. Jujuy',
        navbar_class: 'navbar-jugob',
        headroom_class: 'headroom-jugob',
        favicon: JuGobFav,
        theme_color: '#13304D',
        theme_name: 'jugob',
        defaultImg: DefaultJuGob,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Gob. Jujuy',
        recaptcha_key: '6LdzVjEqAAAAAHrtlH10sb5a4fSq1w1ZyOH7s4pz',
        gtm_ID: 'GTM-TS7BGMNB',
        contact_data: {
            title: <><h5>Secretaría de subastas</h5>
                    <h5>Ministerio de Hacienda y Finanzas</h5>
                    <h5>Gobierno de Jujuy</h5></>,
            lines: [
                <p>
                    <b>Dirección: </b>
                    San Martín 450, Barrio Centro - San Salvador de Jujuy
                </p>,
                <p>
                    <b>Provincia: </b>
                    Jujuy
                </p>,
                <p>
                    <b>Teléfono: </b>
                </p>,
                <p>&emsp; 0388 - 4239442</p>,
                <p>
                    <b>email:</b> subastasMHF@jujuy.gob.ar
                </p>,
            ],
        },
        support_email: 'soportejujuy@esubasta.ar'
    },
};